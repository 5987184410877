import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Layout from '../../../components/layout'
import Nav from '../../../components/nav'
import SEO from '../../../components/seo'
import '../../../styles/index.scss'
import OptBtn from '../../../components/button'

const IndexPage = () => {
    return (
        <Layout lang="es">
            <SEO
                title="Optiwe - Integración con Hubspot"
                description="Integra Optiwe con Hubspot para centralizar tu base de clientes y manejar eficientemente tus clientes potenciales."
                lang="es"
                locales={[
                    {
                        hreflang: 'es',
                        href: 'https://optiwe.com/es/integraciones/hubspot/'
                    }
                ]}
            />

            <main className="container-fluid ow-banner">
                {/*
                ^*********
                * HEADER *
                **********
                */}
                <Nav
                    lang="es"
                    showButtons
                />

                {/*
                ***************
                * MAIN BANNER *
                ***************
                * TODO: If the banner is used in another view, create a <Banner /> component
                */}
                <div className="container ow-banner__content">
                    <div className="row">
                        <div className="col-xl-12 col-xxl-12 ow-banner__home-content">
                            <h1 className="ow-section--cards__title">
                                <span className="bold">
                                    Integra Optiwe con <strong>Hubspot</strong>
                                </span>
                            </h1>
                            <p className="ow-section--cards__desc">
                                Optiwe es un CRM para los canales digitales como Whatsapp,
                                Instagram y Facebook. Una pregunta que usualmente se hacen
                                nuestros clientes es, uso Hubspot como CRM principal, ¿Cómo
                                puedo mantener mi base de clientes en Hubspot, centralizada y
                                actualizada en tiempo real con los datos que entran a Optiwe?
                            </p>
                            <p className="ow-section--cards__desc">
                                Con esta integración, puedes seguir utilizando tus{' '}
                                <a href="https://blog.hubspot.es/marketing/que-son-los-email-workflows-y-como-usarlos-para-cerrar-mas-ventas">
                                    flujos de trabajo
                                </a>{' '}
                                que hoy en dia tienes en Hubspot. Cada cliente o prospecto que
                                inicie una conversación en un canal digital integrado a Optiwe
                                se verá reflejado en Hubspot.
                            </p>
                            <p className="ow-section--cards__desc">
                                La integración entre Hubspot y Optiwe es muy poderosa. Una de
                                las ventajas de Hubspot es la flexibilidad que tiene para
                                almacenar cualquier tipo de información de tus clientes. Esto lo
                                logra a partir de su módulo de{' '}
                                <a href="https://knowledge.hubspot.com/articles/kcs_article/contacts/what-are-contact-properties">
                                    propiedades
                                </a>
                                . La integración con el módulo de propiedades te permitirá
                                mantener toda la información que Optiwe recolecta, ya sea por
                                medio de un chatbot o de un agente humano.
                            </p>
                            <p className="ow-section--cards__desc">
                                Por último, una de las ventajas de esta integración es que por
                                cada conversación generada en Optiwe puedes generar un ticket en
                                Hubspot vinculado con el correspondiente cliente de manera de
                                tener unificada todo el historial de contactos que tuvo el
                                cliente con tu empresa.
                            </p>
                            <p className="ow-section--cards__desc">
                                Para conectar Optiwe con Hubspot hechale un vistazo a nuestro
                                blog:{' '}
                                <a href="https://soporte.optiwe.com/integracion-con-hubspot">
                                    Integración de Optiwe con Hubspot
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="mt-5 mb-5" style={{ display: 'flex', justifyContent: 'center' }}>
                        <OptBtn isLink rounded small href="/es/crear-cuenta/">
                            Regístrate, es grátis!
                        </OptBtn>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default IndexPage
